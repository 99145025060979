<template>
  <!-- used to choose where the dx toasts should appear -->
  <div
    id="toastAnchor"
    style="position: fixed; top: 20px; right: 20px; height: 0"
  ></div>
  <DxLoadPanel
    :visible="loading"
    :shading="true"
    :show-indicator="true"
    :hide-on-outside-click="false"
  />
  <div class="wrapper" v-if="!loading">
    <!-- <div class="login-form">
      <div class="dx-card content">
        <div class="content-block">Login Page</div>
        <DxButton
          text="Sign In"
          width="100%"
          type="default"
          :on-click="onSignInClick"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
// import DxButton from "devextreme-vue/button";
import {
  signIn,
  getTokenClaims,
  signOutAndRedirect,
  myMSALObj,
  getAccessToken,
} from "../auth";
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import notify from "devextreme/ui/notify";

export default {
  setup() {
    const router = useRouter();
    const loading = ref(false);

    // toaster popup code from devExpress library
    const toasterMessage = (msg, type) => {
      notify(
        {
          message: msg,
          width: 300, // Specify width here as a numeric value for pixels or a string for other units
          position: {
            // Use the custom anchor for positioning
            of: "#toastAnchor",
            at: "top right",
            my: "top right",
          },
        },
        type,
        1500
      );
    };

    const updateUserInDatabase = async (claims) => {
      const requestBody = {
        userId: claims.sub,
        email: claims.emails ? claims.emails[0] : "",
        name: claims.name,
        extension_UserType: claims.extension_UserType,
        firstName: claims.given_name,
        lastName: claims.family_name,
      };

      try {
        const accessToken = await getAccessToken();

        const response = await fetch(
          `${process.env.VUE_APP_SAVE_USER_DETAILS_AZ_FUNCTION}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          console.error(
            "Error in Azure Function call: ",
            await response.text()
          );
        }
      } catch (error) {
        console.error("Error making fetch request: ", error.message);
      }
    };

    const checkAuthAndRedirect = async (claims) => {
      if (claims["extension_UserType"] === "Landowner") {
        router.push("/landowner/dashboard");
      } else if (claims["extension_UserType"] === "Developer") {
        router.push("/developer/dashboard");
      } else {
        toasterMessage(`Unknown or incorrect user type`, "warning");
        await signOutAndRedirect(router);
      }
    };

    onMounted(async () => {
      loading.value = true;
      try {
        const accounts = myMSALObj.getAllAccounts();
        if (accounts.length > 0) {
          const claims = getTokenClaims();
          if (claims && claims.sub) {
            console.log(claims);
            await updateUserInDatabase(claims);
            await checkAuthAndRedirect(claims);
          }
        } else {
          await signOutAndRedirect(router);
          await signIn();
          const claims = getTokenClaims();
          console.log(claims);
          if (claims && claims.sub) {
            await updateUserInDatabase(claims);
            await checkAuthAndRedirect(claims);
          } else {
            console.log("User claims not available after sign-in attempt");
            // Redirect to login or handle appropriately
          }
        }
      } finally {
        loading.value = false;
      }
    });

    // const onSignInClick = async () => {
    //   loading.value = true;
    //   try {
    //     await signOutAndRedirect(router);
    //     await signIn();
    //     const claims = getTokenClaims();
    //     console.log(claims);
    //     if (claims && claims.sub) {
    //       await updateUserInDatabase(claims);
    //       checkAuthAndRedirect(claims);
    //     } else {
    //       console.log("User claims not available after sign-in attempt");
    //       // Redirect to login or handle appropriately
    //     }
    //   } finally {
    //     loading.value = false;
    //   }
    // };

    return { loading, toasterMessage };
  },
  components: {
    // DxButton,
    DxLoadPanel,
  },
};
</script>

<style lang="scss" scoped>
@import "../themes/generated/variables.base.scss";
.wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.single-card {
  width: 100%;
  height: 100%;

  .dx-card {
    width: 330px;
    margin: auto auto;
    padding: 40px;
    flex-grow: 0;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 30px;

      .title {
        color: $base-text-color;
        line-height: 28px;
        font-weight: 500;
        font-size: 24px;
      }

      .description {
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 18px;
      }
    }
  }
  .login-form {
    .link {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      margin-top: 20px;

      a {
        text-decoration: none;
      }
    }
  }
}
</style>
