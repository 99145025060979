export default [];

const landownerMenuItems = [
  {
    text: "Dashboard",
    path: "/landowner/dashboard",
    icon: "home",
  },
  {
    text: "Upload Units",
    path: "/landowner/upload-units",
    icon: "add",
  },
  {
    text: "My Units",
    path: "/landowner/my-units",
    icon: "box",
  },
];

const developerMenuItems = [
  {
    text: "Dashboard",
    path: "/developer/dashboard",
    icon: "home",
  },
  {
    text: "Find Units",
    path: "/developer/find-units",
    icon: "find",
  },
  {
    text: "My Orders",
    path: "/developer/my-orders",
    icon: "box",
  },
];

export { landownerMenuItems, developerMenuItems };
