import { createRouter, createWebHistory } from "vue-router";
import { myMSALObj, getTokenClaims } from "./auth"; // Import the MSAL object
import store from "./store/store";
import Profile from "./views/profile-page";
import Tasks from "./views/tasks-page";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";
import AuthResponse from "./views/auth-response";
import loginForm from "./views/login-form";

function loadView(view) {
  return () => import(/* webpackChunkName: "login" */ `./views/${view}.vue`);
}

const router = new createRouter({
  routes: [
    {
      path: "/landowner",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        requiredUserType: "Landowner",
      },
      // child landowner vue's
      children: [
        {
          path: "dashboard",
          name: "landowner-dashboard",
          component: () => import("@/views/landowner/dashboard-page.vue"),
        },
        {
          path: "my-units",
          name: "landowner-units",
          component: () => import("@/views/landowner/view-units.vue"),
        },
        {
          path: "my-units/:unitId/:uid",
          name: "view-unit-details",
          component: () => import("@/views/landowner/view-unit-details.vue"),
        },
        {
          path: "my-units/edit/:unitId/:uid",
          name: "edit-unit-details",
          component: () => import("@/views/landowner/edit-unit-details.vue"),
        },
        {
          path: "upload-units",
          name: "landowner-upload-units",
          component: () => import("@/views/landowner/upload-units.vue"),
        },
        {
          path: "upload-successful",
          name: "landowner-upload-successful",
          component: () => import("@/views/landowner/upload-successful.vue"),
          beforeEnter: (to, from, next) => {
            if (localStorage.getItem("uploadComplete") === "true") {
              // Clear the flag
              localStorage.removeItem("uploadComplete");
              next();
            } else {
              // Redirect to a different page or show an error
              next({ name: "landowner-dashboard" }); // go back to dashboard route of landowner
            }
          },
        },
      ],
    },
    {
      path: "/developer",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        requiredUserType: "Developer",
      },
      children: [
        {
          path: "dashboard",
          name: "developer-dashboard",
          component: () => import("@/views/developer/dashboard-page.vue"),
        },
        {
          path: "find-units",
          name: "developer-find-units",
          component: () => import("@/views/developer/find-units.vue"),
        },
        {
          path: "my-orders/:orderId",
          name: "view-order-details",
          component: () => import("@/views/developer/view-order-details.vue"),
        },
        {
          path: "my-orders",
          name: "developer-orders",
          component: () => import("@/views/developer/view-orders.vue"),
        },
        {
          path: "preview-order-1",
          name: "developer-preview-order-1",
          component: () => import("@/views/developer/preview-order-1.vue"),
          beforeEnter: (to, from, next) => {
            // Check if the basket has items
            if (store.state.matchedUnits.length === 0) {
              next({ name: "developer-find-units" }); // Redirect to find-units if basket is empty
            } else {
              next(); // Proceed if basket has items
            }
          },
        },
        {
          path: "preview-order-2",
          name: "developer-preview-order-2",
          component: () => import("@/views/developer/preview-order-2.vue"),
          beforeEnter: (to, from, next) => {
            // Check if the basket has items
            if (store.state.matchedUnits.length === 0) {
              next({ name: "developer-find-units" }); // Redirect to find-units if basket is empty
            } else {
              next(); // Proceed if basket has items
            }
          },
        },
        {
          path: "purchase-complete",
          name: "developer-purchase-complete",
          component: () => import("@/views/developer/purchase-complete.vue"),
          beforeEnter: (to, from, next) => {
            if (localStorage.getItem("orderComplete") === "true") {
              // Clear the flag
              localStorage.removeItem("orderComplete");
              next();
            } else {
              // Redirect to a different page or show an error
              next({ name: "developer-dashboard" }); // Replace 'Home' with an appropriate route
            }
          },
        },
      ],
    },
    // common routes...
    {
      path: "/auth-response",
      name: "auth-response",
      component: AuthResponse,
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        requiredUserType: "Landowner",
      },
      component: Profile,
    },
    {
      path: "/tasks",
      name: "tasks",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        requiredUserType: "Landowner",
      },
      component: Tasks,
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
      },
      component: loginForm,
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description:
          "Please enter the email address that you used to register, and we will send you a link to reset your password via Email.",
      },
      component: loadView("reset-password-form"),
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up",
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password",
      },
      component: loadView("change-password-form"),
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/login-form", // Redirect all unrecognized paths to root
    },
  ],
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
  const accounts = myMSALObj.getAllAccounts();
  const claims = getTokenClaims();

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the route requires authentication
    if (!accounts.length || !isValidClaim(claims, to)) {
      // No account or invalid claim for the route
      next({ name: "login-form" });
    } else {
      // User is authenticated and has valid claim, proceed to route
      next();
    }
  } else {
    // No auth required for the route, proceed
    next();
  }
});

function isValidClaim(claims, route = {}) {
  if (route.meta && route.meta.requiredUserType) {
    return (
      claims && claims["extension_UserType"] === route.meta.requiredUserType
    );
  }
  return true; // Default to true if no specific claim required
}

export default router;
