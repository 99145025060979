// store.js (or your Vuex store file)
import { createStore } from "vuex";

export default createStore({
  state: {
    matchedUnits: [], // Array of { requestedUnit, matchedUnits[] }
    unmatchedUnits: [],
    unitsFormData: [], // Array of filled in unit forms
    orderMatchStatus: null, // this value is used to hold the response value from the pricing calculator
  },
  mutations: {
    setMatchedUnits(state, mappings) {
      // Each mapping is an object { requestedUnit, matchedUnits[] }
      state.matchedUnits = mappings.results;
    },
    clearMatchedUnits(state) {
      state.matchedUnits = [];
    },
    setUnmatchedUnits(state, mappings) {
      // Each mapping is a unit object { }
      state.unmatchedUnits = mappings.unmatchedUnits;
    },
    clearUnmatchedUnits(state) {
      state.unmatchedUnits = [];
    },
    // New mutations for units form data
    setUnitsFormData(state, units) {
      state.unitsFormData = units;
    },
    clearUnitsFormData(state) {
      state.unitsFormData = [];
    },
    // Mutation to set the order match status
    setOrderMatchStatus(state, status) {
      state.orderMatchStatus = status;
    },
    // Mutation to clear the order match status
    clearOrderMatchStatus(state) {
      state.orderMatchStatus = null;
    },
  },
  // Add actions and getters as necessary
});
